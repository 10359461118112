const stringBetweenSquareBracketRegex = /\[(.*?)\]/;

/**
 * @param str {String}
 * @return {String} string
*/
export const isStringHasSquareBracket = (str = '') => !!(str.match(stringBetweenSquareBracketRegex));
/**
 *
 * @param {String} desc
 * @return {String} string
 */
export const convertLinkDescription = (desc) => {
  if (!desc.match(stringBetweenSquareBracketRegex)) {
    return false;
  }
  const [title, url] = desc.match(stringBetweenSquareBracketRegex)[1].split('-');
  return `${desc.replace(stringBetweenSquareBracketRegex, '')} <a style="color: #3273dc;font-size: 12px;" href="${url}" target="_blank">${title}</a>`;
};
