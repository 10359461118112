<template> 
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.410582 0.410704C0.736019 0.0852667 1.26366 0.0852667 1.58909 0.410704L5.99984 4.82145L10.4106 0.410704C10.736 0.0852667 11.2637 0.0852667 11.5891 0.410704C11.9145 0.736141 11.9145 1.26378 11.5891 1.58922L7.17835 5.99996L11.5891 10.4107C11.9145 10.7361 11.9145 11.2638 11.5891 11.5892C11.2637 11.9147 10.736 11.9147 10.4106 11.5892L5.99984 7.17847L1.58909 11.5892C1.26366 11.9147 0.736019 11.9147 0.410582 11.5892C0.0851446 11.2638 0.0851446 10.7361 0.410582 10.4107L4.82133 5.99996L0.410582 1.58922C0.0851446 1.26378 0.0851446 0.736141 0.410582 0.410704Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '12'
    },
    height: {
      type: String,
      default: '12'
    },
    fill: {
      type: String,
      default: '#ffff'
    }
  }
};
</script>

<style lang="scss" scoped></style>
