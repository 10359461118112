<template>
  <div class="image-viewer">
    <div
      class="image-viewer__close"
      @click="$emit('close')"
    >
      <CloseIcon />
    </div>
    <div class="image-viewer__component">
      <div
        v-show="!isMobileSize"
        class="image-viewer__component__prev"
        @click="onActions('prev')"
      >
        <Chevron
          width="40"
          height="20"
          custom-class="image-viewer__component__prev__icon"
        />
      </div>

      <div
        ref="scrolling-element"
        class="image-viewer__component__image"
        @scroll="event => handleScroll(event.target)"
      >
        <div>
          <ImageLazy
            :key="selected"
            :src="imageUrl"
            :alt="alt"
            @click="toViewImage"
          />
        </div>
      </div>

      <div
        v-show="!isMobileSize"
        class="image-viewer__component__next"
        @click="onActions('next')"
      >
        <Chevron
          width="40"
          height="20"
          class="image-viewer__component__next__icon"
        />
      </div>
    </div>

    <div class="image-viewer__pagination">
      <div
        v-for="index of data.length"
        :key="index"
        :class="['image-viewer__pagination__item', { selected: index === selected }]"
        @click="onSelected(index)"
      />
    </div>
  </div>
</template>

<script>
import Chevron from './Chevron.vue';
import CloseIcon from './CloseIcon.vue';
import mixinMobile from '@/misc/mixinMobile';
import ImageLazy from '../ImageLazy/index.vue';

export default {
  name: 'ImageViewer',
  components: {
    Chevron,
    CloseIcon,
    ImageLazy,
  },
  mixins: [mixinMobile],
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    alt: {
      type: String,
      default: 'lion parcel',
    },
  },
  data() {
    return {
      select: 1,
      debounce: {
        timeout: 0,
        duration: 100,
      },
    };
  },
  computed: {
    imageUrl() {
      return this.data[this.select - 1];
    },
    selected() {
      return this.select;
    },
  },
  mounted() {
    this.src = this.data[0];
  },
  created() {
    if (!this.isSsr()) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = 'no';
    }
  },
  beforeDestroy() {
    if (!this.isSsr()) {
      document.documentElement.removeAttribute('style');
      document.body.scroll = 'yes';
    }
  },
  methods: {
    onActions(type) {
      if (type === 'prev' && Boolean(this.select - 1)) this.select -= 1;
      if (type == 'next' && this.select !== this.data.length) this.select += 1;
    },
    handleScroll(val) {
      if (Math.ceil(val.offsetWidth / val.scrollLeft) === 1) {
        this.onActions('next');
        if (this.isMobileSize) this._debounce(val);
      }

      if (Math.ceil(val.offsetWidth / val.scrollLeft) === Infinity) {
        this.onActions('prev');
        if (this.isMobileSize) this._debounce(val);
      }
    },
    _debounce(val) {
      if (this.debounce.timeout) {
        clearTimeout(this.debounce.timeout);
      }
      this.debounce.timeout = setTimeout(() => {
        val.scrollLeft = val.offsetWidth / 2;
        val.childNodes[0].childNodes[0].style = 'display: flex; justify-content: center;';
      }, this.debounce.duration);
    },
    onSelected(index) {
      this.select = index;
      this._debounce(this.$refs['scrolling-element']);
    },
    toViewImage() {
      if (!this.isSsr()) {
        window.open(this.data[this.selected - 1], '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
