<template>
  <svg
    :width="width"
    :height="height"
    :class="customClass"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.70711 0.792893C9.09763 1.18342 9.09763 1.81658 8.70711 2.20711L2.41421 8.5L8.70711 14.7929C9.09763 15.1834 9.09763 15.8166 8.70711 16.2071C8.31658 16.5976 7.68342 16.5976 7.29289 16.2071L0.292893 9.20711C-0.0976311 8.81658 -0.0976311 8.18342 0.292893 7.79289L7.29289 0.792893C7.68342 0.402369 8.31658 0.402369 8.70711 0.792893Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      width: {
        type: String,
        default: '9'
      },
      height: {
        type: String,
        default: '17'
      },
      fill: {
        type: String,
        default: '#4D4D4D'
      },
      customClass: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
