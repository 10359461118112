<template>
  <div class="do-flex result-track__item__card">
    <div class="do-flex-col mr-3">
      <div style="width: 32px;">
        <img
          :src="'/images/tracking/'+ img"
          alt="tracking.png"
          class="img-info-track"
        >
      </div>
      <div
        v-if="showBorder"
        class="do-border"
      />
    </div>
    <div class="do-flex-col info-track">
      <div>
        {{ title }}
      </div>
      <div>
        <p v-html="decideDescription" />
        <span 
          v-if="(isPOD || isDEX) && checkEmptyAttachment"
          class="span-link"
          @click="onOpen"
        >
          Lihat Bukti Pengiriman
        </span>
      </div>
    </div>    
    <ImageViewer
      v-if="(isPOD || isDEX) && showModal"
      :data="attachment"
      @close="onClose"
    />
  </div>
</template>

<script>
import {isStringHasSquareBracket, convertLinkDescription} from "../app/util/utils";
import ImageViewer from "@/components/image-viewer/ImageViewer.vue";

export default {
  name: "CardTrackResult",
  components: { ImageViewer },
  props: {
    showBorder: {
      type: Boolean,
      default: true,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    isBKD: {
      type: Boolean,
      required: true,
    },
    isPOD: {
      type: Boolean,
      required: false,
    },
    isDEX: {
      type: Boolean,
      required: true,
    },
    isRTSHQ: {
      type: Boolean,
      required: true,
    },
    attachment: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    checkEmptyAttachment() {
      const containEmptyString = (element) => element === '';
      return this.attachment == null ? false : (this.attachment.some(containEmptyString) ? false : true)
    },
    decideDescription() {
      if (isStringHasSquareBracket(this.desc)){
        return convertLinkDescription(this.desc)
      }
      if(this.isRTSHQ) {
        const stt = this.desc.split(" ").pop();
        const url = 'stt?q=' + stt.replace(".", "");
        const sttDesc = `<a href='${url}'>${stt}<a/>`;
        const temptDesc = this.desc.split(" ").slice(0, -1).join(" ");
        return temptDesc + ' ' + sttDesc
      }
      if (this.isBKD || this.isPOD) {
        return this.desc + '.'
      }
      return this.desc
    }
  },
  methods: {
    onOpen() {
      this.showModal = true
    } ,
    onClose() {
      this.showModal = false
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/spacing.scss";
@import "src/assets/css/flexbox";
@import "src/assets/css/style";

$default-width: 38vw;

.result-track__item__card {
  width: $default-width;
  padding: 0 20px 0 20px;

  @include for-size(mobile) {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  @include for-size(xmobile) {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  @include for-size(tablet-portrait) {
    width: 100%;
  }
}

.info-track {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.21px;
  margin-bottom: 18px;

  @include for-size(mobile) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.21px;
  }

  .full-desc {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .truncate-desc {
    display: block;
  }

  &:hover {
    .full-desc {
      z-index: 1;
      visibility: visible;
      opacity: 1;
      position: relative;
      padding: .05rem .25rem;
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.3);
      background-color: #FFFFFF;
    }

    .truncate-desc {
      display: none;
    }
  }
}

.img-info-track {
  width: 100%;
  height: 100%;
}

.do-border {
  border-left: 2px solid #D4D5D8;
  height: 50%;
  margin: auto;
}

.span-link {
  color: $color-aqua;
  cursor: pointer;
  text-decoration: none;

  &:hover {    
    color: $color-base-text;
  }
}
</style>
